import { LocalStorageService } from 'ngx-webstorage';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { io } from "socket.io-client";
import { BehaviorSubject, Observable } from 'rxjs';
import { ChatHistoryCriteria, ChatMessage } from '../components/chat/services/chat.service';
import { th } from 'date-fns/locale';
import { ThemeService } from 'ng2-charts';
export enum SocketStates {
  'closed' = 0,
  'open' = 1,
  'closedManually' = 2,
  'reconnect' = 3
}

@Injectable({
  providedIn: 'root'
})
export class SocketIoChatService {

    private url = environment.protocol + environment.nodeServer;
    private socket;
    private subscribeData = {
      DeviceIds: [],
      Channels: ['vital-params', 'last-vital-params']
    }
    private socketConnected: boolean = false;
    private socketState: BehaviorSubject<SocketStates> = new BehaviorSubject(SocketStates.closed);
    public socketState$: Observable<any> = this.socketState.asObservable();

    constructor(
      private auth: AuthService,
      private localStorage: LocalStorageService
    ) {
    }
    connect() {
      if (this.socketConnected == false) {
        this.socket = io(this.url, {
          query: {
            WebToken: this.auth.getToken(),
            Lang: this.localStorage.retrieve('currentLang') || 'en',
            ClientType: 'ChatWeb'
          },
          transports: ['websocket']
        });
        this.socket.on('connect', this.onConnect.bind(this));
        this.socket.on('disconnect', this.onDisconnect.bind(this));
        this.socket.io.on('reconnect', this.onReconnect.bind(this));
      }
    }

    getSocket() {
      return this.socket;
    }

    disconnect() {
      this.setSocketState(SocketStates.closed);
    }

    isConnected() {
      return this.socket?.connected;
    }
  
    onConnect(event: any) {
     if (this.socketConnected === false) {
      console.log('ccccccccccccccccccccccc')
        this.setSocketState(SocketStates.open);
        this.socketConnected = true;
     } 
    }

    onReconnect(event: any) {
      this.setSocketState(SocketStates.reconnect);
      console.log('Socket.IO connection established');
    }
  
    setSocketState(state: SocketStates) {
      this.socketState.next(state);
    }
  
    onDisconnect(reason: any) {
      console.log(`Socket.IO connection closed: ${reason}`);
      this.setSocketState(SocketStates.closed);
    }
  
    getVitals(callback: (message: string) => void) {
      this.socket.on('vital-params', callback);
    }
  
    getLastVitals(callback: (message: string) => void) {
      this.socket.on('last-vital-params', callback);
    }
  
    sendChatMessage(text: any, callback: (response: any) => void) {
      this.socket.emit('patient-message', text);
    }
  
    receiveChatMessage(callback: (message: string) => void) {
      this.socket.on('patient-message', callback);
    }
  
    stopListeningForChatMessages() {
      this.socket.off('patient-message');
    }
  
    joinChatRoom(room: string) {
      this.socket.emit('join-room', [room], function (callback: any) {
        console.log('subscribe', callback);
      });
    }
  
    leaveChatRoom(room: string) {
      this.socket.emit('leave-room', [room], function (callback: any) {
        console.log('left room', callback);
      });
    }
  
    getUnreadMessages(room: string, callback: (response: any) => void) {
      this.socket.emit('patient-unseen-messages', { room }, callback);
    }
  
    setMessagesAsSeen(messages: ChatMessage[]) {
      this.socket.emit('patient-messages-seen', { messages });
    }
  
    getChatHistory(criteria: ChatHistoryCriteria, callback: (response: any) => void) {
      this.socket.emit('find-patient-history', criteria, callback);
    }
  
    sendCallOffer(roomId: any, callback: (data: any) => void) {
      this.socket.emit('call-offer', roomId, callback);
    }
  
  
    listenForIncomingCalls(callback: (roomId: any, peerHash: any, userId: any, username: any) => void) {
      this.socket.on('receive-offer', callback);
    }
  
    answerCallOffer(roomId: any, callback: (data: any) => void) {
      this.socket.emit('call-answer', roomId, callback);
    }
  
    receiveCallAnswer(callback: (roomId: any, userHash: any, userId: any, username: any) => void) {
      this.socket.on('receive-answer', callback);
    }
  
    rejectCall(roomId: any) {
      this.socket.emit('call-rejection', roomId);
    }
  
    listenForCallRejection(callback: (roomId: any, userId: any, username: any) => void) {
      this.socket.on('call-rejected', callback);
    }
  
    checkForActiveCall(roomId: any, callback: (response: any) => void) {
      this.socket.emit('check-for-active-call', roomId, callback);
    }
  
    disconnectPeer(roomId: any) {
      this.socket.emit('disconnect-peer', roomId);
    }
  
    listenForPeerDisconnects(callback: (roomId: any, userHash: any, userId: any, username: any) => void) {
      this.socket.on('peer-disconnected', callback);
    }
  
    endCall(roomId: any) {
      this.socket.emit('end-call', roomId);
    }
  
    listenForCallEnd(callback: (roomId: any) => void) {
      this.socket.on('call-ended', callback);
    }
  
    joinOngoingCall(roomId: any, callback: (roomId: any, peerHash: any, userId: any, username: any) => void) {
      this.socket.emit('get-user-hash', roomId, callback);
    }
  
    roomParticipants(callback: (roomId: any, participants: any[]) => void) {
      this.socket.on('room-participants', callback);
    }

}
